<template>
  <div v-loading.fullsccreen.lock="loading">
    <!-- Header -->
    <div class="header bg-gradient-primary py-5 py-lg-6 pt-lg-6">
      <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Acesso de Empresas</h1>
              <p class="text-lead text-white">
                Entre com as suas credencias no formulário abaixo.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">

            <div class="card-body px-lg-5 py-lg-5">
              <form @submit.prevent="onSubmit">
                <base-input type="email" label="E-mail" placeholder="Email" name="E-mail" v-model="email" required>
                </base-input>

                <base-input
                  label="Senha"
                  name="Senha"
                  type="password"
                  placeholder="Senha"
                  v-model="senha"
                  required
                >
                </base-input>
                <base-alert dismissible type="warning" v-if="errLogin.status">
                  <strong>Ops!</strong> {{ errLogin.msg }}
                </base-alert>
                <div class="text-center">
                  <base-button type="primary" class="my-4" native-type="submit"
                    >Entrar</base-button
                  >
                </div>
                <div class="text-center text-muted">
                  <small
                    >Esqueceu a sua senha?
                    <router-link to="/recuperarsenha" tag="a">
                      Recupere aqui</router-link
                    >.</small
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { Loading } from 'element-ui';
import { userKey } from "@/global"
import axios from 'axios';

Vue.use(Loading.directive);

export default {
  components: {},
  data() {
    return {
      email: '',
      senha: '',
      errLogin: {
        status: false,
        msg: ''
      },
      loading: false
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const login = {
        client_id: "empresamatchjob",
        client_secret:
          "A6FB41C704FB2369F866AFFAB27B430E00845768DEF617936025FCF14795221C",
        grant_type: "password",
        username: this.email,
        password: this.senha,
      };
      //console.log("variavel user -> ", login);

      const http = axios.create({
        baseURL: "https://api.empresa.matchjob.com.br/",
        //baseURL: "http://api.empresa.matchjob.local/",
        //baseURL: process.env.VUE_APP_BASE_URL,
        headers: {
          //'Access-Control-Allow-Origin': '*',
          //"Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        }
      });

      //await this.$http
      await http
        .post("login/entrar", login)
        .then((res) => {
          localStorage.setItem(userKey, JSON.stringify(res.data));
          localStorage.setItem('access_token', res.data.full.access_token);
          const dateNow = new Date();
          const dateFinal = new Date(dateNow.getTime() + (parseInt(res.data.full.expires_in) * 1000));
          localStorage.setItem('session_end_datetime', dateFinal);
          setTimeout(() => {
            this.$router.push({ path: "/dashboard" }).catch(() => {});
          }, 1000);
        })
        .catch((err) => {
          this.onErrorLogin(err.response.data.error_description);
          console.log("Erro na chamada do login ->> ", err);
        });
    },
    onErrorLogin(value) {
      this.loading = false;
      this.errLogin.status = true;
      this.errLogin.msg = value;
      setTimeout(() => {
        this.errLogin.status = false;
      }, 5000);
    }
  }
}
</script>
